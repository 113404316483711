var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "merchantadd-container position-relative" }, [
    _c(
      "div",
      { staticClass: "merchant-form" },
      [
        _c(
          "el-form",
          {
            ref: "addForm",
            attrs: {
              "label-width": "120px",
              model: _vm.merchantList,
              inline: true,
              "hide-required-asterisk": true,
            },
          },
          [
            _c(
              "div",
              [
                _c("div", { staticClass: "d-flex a-center mb-30" }, [
                  _c("img", {
                    staticClass: "mr-15",
                    staticStyle: { width: "3px", height: "14px" },
                    attrs: { src: require("@/assets/image/line.png"), alt: "" },
                  }),
                  _c("div", { staticClass: "main-color font-17" }, [
                    _vm._v("业务信息"),
                  ]),
                ]),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "供应商", prop: "merchantId" } },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.merchantList.merchantName)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "业务员", prop: "merchantPhone" } },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.merchantList.merchantPhone)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "line-style mb-30" }),
                _c("div", { staticClass: "d-flex a-center mb-30" }, [
                  _c("img", {
                    staticClass: "mr-15",
                    staticStyle: { width: "3px", height: "14px" },
                    attrs: { src: require("@/assets/image/line.png"), alt: "" },
                  }),
                  _c("div", { staticClass: "main-color font-17" }, [
                    _vm._v("商品信息"),
                  ]),
                ]),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品名称", prop: "areaIds" } },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.merchantList.areaNames)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品主图", prop: "areaIds" } },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.merchantList.areaNames)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "视频", prop: "areaIds" } },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.merchantList.areaNames)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy1" },
      [_c("el-button", { on: { click: _vm.goBack } }, [_vm._v("返回")])],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }